// CompetentTrainerInfoForm.js

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CardHeading from "../CardHeading";
import "react-datetime/css/react-datetime.css"; 
import { GetUser, AddUser, UpdateUser } from "../../apiCalls/UserCalls";
import { GetAllLocations } from "../../apiCalls/LocationCalls";
import { GetAllRegions } from "../../apiCalls/RegionCalls";
import { useSelector, useDispatch } from "react-redux";
import { GetAllCompanies, GetCompany } from "../../apiCalls/CompanyCalls";
import { useAlert } from "../../context/AlertContext";
import { setAllUserInfo } from "../../redux/reducers/UserReducer";
import Select from "react-select";
import DeleteIcon from "../../assets/images/icon-x-indigo.png"; 

function CompetentTrainerInfoForm(props) {
  const action = props.action.trim() || "update";
  const cardTitle =
    action === "update"
      ? "Edit Competent Trainer"
      : "Add New Competent Trainer";
  const buttonTitle = action === "update" ? "Edit Trainer" : "Add Trainer";
  const current_user = useSelector((state) => state.user);
  const current_user_role = current_user.role;
  const passedInId = props.id != null ? props.id : 0;
  const { addAlert } = useAlert();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company_selection:
        action === "add" ? current_user.company_id.toString() : "",
      region_selection:
        action === "add" ? current_user.region_id.toString() : "",
      user_permissions: "",
    },
  });
  const password = watch("password");

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null); // For multi-location selection
  const [locationList, setLocationList] = useState([]); // Store selected locations
  const [locationsForSelect, setLocationsForSelect] = useState([]); // For location dropdown
  const [allRegions, setAllRegions] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [companyChoices, setCompanyChoices] = useState([]);
  const [regionChoices, setRegionChoices] = useState([]);
  const [locationChoices, setLocationChoices] = useState([]);

  // Fetch all companies, regions, and locations
  useEffect(() => {
    const fetchInitialData = async () => {
		let companiesResult;
		let regionsResult;
		let locationsResult;

		if (current_user_role >= 5) {
			// Super Admin: Fetch all companies
			companiesResult = await GetAllCompanies();
			regionsResult = await GetAllRegions();
			locationsResult = await GetAllLocations();
		} else {
			// Other users: Fetch only their company's info 
			companiesResult = await GetCompany(current_user.company_id);
			regionsResult = await GetAllRegions('company', current_user.company_id);
			locationsResult = await GetAllLocations('company', current_user.company_id);
			if (companiesResult.success) {
				// Wrap the single company object in an array
				console.log(companiesResult.data);
				companiesResult.data = [companiesResult.data];
			}
		}

		if (companiesResult.success) {
			setCompanyChoices(companiesResult.data);
		}
		if (regionsResult.success) {
			setAllRegions(regionsResult.data);
			setRegionChoices(regionsResult.data);
		}
		if (locationsResult.success) {
			setAllLocations(locationsResult.data);
			setLocationChoices(locationsResult.data);
		}
    };

    fetchInitialData();
  }, [current_user.company_id, current_user_role]);

  // Initialize form for 'add' action
  useEffect(() => {
    if (action === "add" && current_user && locationChoices.length > 0) {
      setSelectedCompany(current_user.company_id.toString());
      setSelectedRegion(current_user.region_id.toString());

      if (current_user.location_id) {
        let currentUserLocationIds = current_user.location_id
          ? current_user.location_id.split(",").map((id) => id.trim())
          : [];

        const selectedLocations = locationChoices.filter((location) =>
          currentUserLocationIds.includes(location.id.toString())
        );
        setLocationList(selectedLocations);
      }
      setSelectedLocation(null);
    }
// eslint-disable-next-line
}, [action, current_user]);

  // Get user info for editing
  useEffect(() => {
    async function fetchUser() {
      const result = await GetUser(passedInId);
      if (result.success) {
        const data = result.data;
        setValue("first_name", data.first_name);
        setValue("last_name", data.last_name);
        setValue("email", data.email);
        setValue("phone", data.phone);

        // Parse the location_id string into an array of IDs
        let locationIds = data.location_id
          ? data.location_id.split(",").map((id) => id.trim())
          : [];

        // Now get the location objects for these IDs
        const selectedLocations = locationChoices.filter((location) =>
          locationIds.includes(location.id.toString())
        );
        setLocationList(selectedLocations);

        setValue("user_permissions", data.role.toString());
        setValue("company_selection", data.company_id.toString());
        setValue("region_selection", data.region_id.toString());
        setSelectedCompany(data.company_id.toString());
        setSelectedRegion(data.region_id.toString());
        setSelectedLocation(null);
      }
    }
    if (action === "update" && passedInId !== 0 && locationChoices.length > 0) {
      fetchUser();
    }
  }, [passedInId, action, setValue, locationChoices]);

  // Manage location dropdown
  useEffect(() => {
    const allLocations = locationChoices.map((location) => ({
      value: location.id,
      label: location.name,
    }));
    const filteredLocations = allLocations.filter(
      (location) =>
        !locationList.some((selected) => selected.id === location.value)
    );

    setLocationsForSelect(filteredLocations);
  }, [locationChoices, locationList]);

  const handleLocationChange = (selectedOption) => {
    const selectedLocation = locationChoices.find(
      (location) => location.id === selectedOption.value
    );
    setSelectedLocation({
      value: selectedLocation.id,
      label: selectedLocation.name,
    });
  };



  	// Handler for company selection change
	const handleCompanyChange = (selectedCompanyId) => {
		console.log("selectedCompanyId", selectedCompanyId);
		setSelectedCompany(selectedCompanyId);
		setValue("company_selection", selectedCompanyId); // Sync with form value

		// Filter regions based on selected company
		const filteredRegions = allRegions.filter(
		(region) => region.company_id.toString() === selectedCompanyId
		);
		setRegionChoices(filteredRegions);
		console.log("filteredRegions", filteredRegions);

		// Reset selected region and location when company changes
		setSelectedRegion("");
		setSelectedLocation("");
		setValue("region_selection", ""); // Reset in form
		setValue("location_selection", ""); // Reset in form
		setLocationChoices([]);
	};

	// Handler for region selection change
	const handleRegionChange = (selectedRegionId) => {
		setSelectedRegion(selectedRegionId);
		setValue("region_selection", selectedRegionId); // Sync with form value

		// Filter locations based on selected region
		const filteredLocations = allLocations.filter(
		(location) => location.region_id.toString() === selectedRegionId
		);
		setLocationChoices(filteredLocations);
		console.log("filteredLocations", filteredLocations);

		// Reset selected location when region changes
		setSelectedLocation("");
		setValue("location_selection", ""); // Reset in form
	};

 


	// Handler for location selection change
	const handleAddLocationList = () => {
		if (!selectedLocation) return;
		let locationListIds = locationList.map((location) =>
		location.id.toString()
		);
		if (locationListIds.includes(selectedLocation.value.toString())) {
		return;
		}
		const selectedLocationFull = locationChoices.find(
		(location) => location.id === selectedLocation.value
		);
		setLocationList([...locationList, selectedLocationFull]);
		const updatedLocationsForSelect = locationsForSelect.filter(
		(location) => location.value !== selectedLocation.value
		);
		setLocationsForSelect(updatedLocationsForSelect);
		setSelectedLocation(null);
	};

	const handleDeleteLocationList = (id) => {
		const newLocationList = locationList.filter(
		(location) => location.id !== id
		);
		setLocationList(newLocationList);
		const removedLocation = locationChoices.find(
		(location) => location.id === id
		);
		const updatedLocationsForSelect = [
		...locationsForSelect,
		{ value: removedLocation.id, label: removedLocation.name },
		];
		updatedLocationsForSelect.sort((a, b) => a.label.localeCompare(b.label));
		setLocationsForSelect(updatedLocationsForSelect);
	};

	const onSubmit = (formData) => {
		if (formData.phone) {
			formData.phone = formData.phone.replace(/\D/g, "");
		}

	// Construct the submission object with the correct key names
    const submissionDataUser_Add = {
		// this is redundant but necessary for the API
		Username: formData.email,
		Password: formData.password,
		email: formData.email,
		password: formData.password,
		customAttributes: [
		  { name: "first_name", value: formData.first_name },
		  { name: "last_name", value: formData.last_name },
		  { name: "phone", value: formData.phone },
		  { name: "company_id", value: formData.company_selection },
		  { name: "region_id", value: formData.region_selection },
		  { name: "location_id", value: locationList.map((location) => location.id).join(",") },
		  { name: "role", value: formData.user_permissions },
		],
	  };

	const submissionDataUser_Update = {
		first_name: formData.first_name,
		last_name: formData.last_name,
		phone: formData.phone,
		company_id: formData.company_selection,
		region_id: formData.region_selection,
		location_id: locationList.map((location) => location.id).join(","), // Pass location IDs as comma-separated string
		role: formData.user_permissions,
		email: formData.email,
	};
	

	if (action === "update") {
		UpdateUser(passedInId, submissionDataUser_Update).then(async (result) => {
			if (result.success) {
			addAlert("User Updated", "success", false);
			if (typeof props.onSuccessfulOperation === "function") {
				props.onSuccessfulOperation();
			}
			if (props.onClose) {
				props.onClose();
			}
			if (passedInId.toString() === current_user.id.toString()) {
				const entityInfo = await GetUser(passedInId);
				dispatch(setAllUserInfo(entityInfo.data));
			}
			} else {
			addAlert("An error occurred!", "error", true);
			}
		});
	} else if (action === "add") {
		AddUser(submissionDataUser_Add).then(async (result) => {
			if (result.success) {
			addAlert("User Added", "success", false);
			if (typeof props.onSuccessfulOperation === "function") {
				props.onSuccessfulOperation();
			}
			if (props.onClose) {
				props.onClose();
			}
			} else {
			addAlert("An error occurred!", "error", true);
			}
		});
	}
  };

  return (
    <>
      <CardHeading title={cardTitle} />
      <form>
        {/* Existing form fields */}
        <div className="row">
          <div
            className={`half-width ${errors["first_name"] ? "has-error" : ""}`}
          >
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              placeholder={`First Name`}
              {...register("first_name", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>
          <div
            className={`half-width ${errors["last_name"] ? "has-error" : ""}`}
          >
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              placeholder={`Last Name`}
              {...register("last_name", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className={`half-width`}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder={`Email`}
              {...register("email", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>

          <div className={`half-width`}>
            <label htmlFor="phone">Phone Number</label>
            <input
              type="phone"
              name="phone"
              placeholder={`Phone`}
              {...register("phone", {
                required: true,
                minLength: 10,
                maxLength: 18,
              })}
            />
          </div>
        </div>

        {/* Existing region and company dropdowns */}
        <div className="row">
          <div
            className={`half-width ${
              errors["company_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="company_selection">Company</label>
            <select
              name="company_selection"
              {...register("company_selection", { required: true })}
              onChange={(e) => handleCompanyChange(e.target.value)}
              className={current_user_role <= 4 ? "select-readonly" : ""} // Disable if user is not at least a Company Admin
              value={selectedCompany}
            >
              <option value="">Select...</option>
              {companyChoices.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className={`half-width ${
              errors["region_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="region_selection">Region</label>
            <select
              name="region_selection"
              {...register("region_selection", { required: true })}
              onChange={(e) => handleRegionChange(e.target.value)}
              className={current_user_role <= 3 ? "select-readonly" : ""} // Disable if user is not at least a Region Admin
              value={selectedRegion}
			  disabled={current_user_role <= 3 || !selectedCompany} // Disable if no company is selected or user is not a Company Manager
            >
              <option value="">Select...</option>
              {regionChoices.map((region) => (
                <option key={region.id} value={region.id}>
                  {region.name}
                </option>
              ))}
            </select>
          </div>
        </div>
		{/* Conditionally render password field */}
        {action === "add" && (
          <div className="row">
            <div
              className={`half-width ${errors["password"] ? "has-error" : ""}`}
            >
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/,
                    message:
                      "Password must contain an uppercase, lowercase, number, and special character",
                  },
                })}
              />
              Password must be at least 6 characters and contain an uppercase,
              lowercase, number, and special character
              {errors.password && <p>{errors.password.message}</p>}
            </div>
            <div
              className={`half-width ${
                errors["retypePassword"] ? "has-error" : ""
              }`}
            >
              <label htmlFor="retypePassword">Retype Password</label>
              <input
                type="password"
                name="retypePassword"
                placeholder="Retype Password"
                {...register("retypePassword", {
                  required: "Please retype your password",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />
              {errors.retypePassword && <p>{errors.retypePassword.message}</p>}
            </div>
          </div>
        )}
		<div className="row">
		<div
            className={`half-width ${
              errors["user_permissions"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="user_permissions">Permissions</label>
            <select
              name="user_permissions"
              {...register("user_permissions", { required: true })}
            >
              <option value="">Select...</option>
              {current_user_role >= 1 && <option value="1">Trainer</option>}
              {current_user_role >= 2 && (
                <option value="2">Location Manager</option>
              )}
              {current_user_role >= 3 && (
                <option value="3">Region Manager</option>
              )}
              {current_user_role >= 4 && (
                <option value="4">Company Manager</option>
              )}
              {current_user_role >= 5 && (
                <option value="5">Q4 Super Admin</option>
              )}
            </select>
          </div>

		</div>

        {/* Multi-location section */}
        <div className="add-locations-list">
          <h4>* Trainers will be linked to the selected locations</h4>
          <h3>Choose Locations:</h3>
          <div className="row">
            <div className="half-width">
              <label htmlFor="location">Add a Location:</label>
              <Select
                name="location"
                value={selectedLocation}
                onChange={handleLocationChange}
                options={locationsForSelect}
                classNamePrefix={"react-select"}
                placeholder={`Select a location`}
				className={current_user_role <= 2 ? "select-readonly" : ""} // Disable if user is not at least a Region Admin
				isDisabled={current_user_role <= 2 || !selectedRegion}
              />
            </div>
            <div className="quarter-width">
              <button
                type="button"
                className={`button-blue ${selectedLocation ? "" : "disabled"}`}
                onClick={handleAddLocationList}
                disabled={!selectedLocation}
              >
                Add Location
              </button>
            </div>
          </div>
          <h3>Selected Locations:</h3>
          <table className="location-table">
            <thead>
              <tr className="table-header-row">
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {locationList.length > 0 ? (
                locationList.map((location) => (
                  <tr key={location.id} className="table-row">
                    <td>{location.name}</td>
                    <td>
                      <img
                        src={DeleteIcon}
                        className="delete-icon"
                        alt="Delete"
                        onClick={() => handleDeleteLocationList(location.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>No locations selected yet</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="form-bottom-button-wrapper">
          <button
            type="submit"
            className="button button-blue"
            onClick={handleSubmit(onSubmit)}
          >
            {buttonTitle}
          </button>
        </div>
      </form>
    </>
  );
}

export default CompetentTrainerInfoForm;
